<template>
	<div id="app">
		<div class="flex login-bg items-center min-h-screen ">
			<div class="w-full ">
				<div class="">
					<img v-if="isNULLImage" src="@/assets/logo.png" alt="" class="mb-14 max-w-full mx-auto">
          <img v-else :src="companyLogo" alt="" class="mb-14 max-w-full mx-auto logo">
					<div class="bg-white rounded-lg px-6 py-8 m-4">
						<form @submit.prevent="login">
							<div class="mb-8">
								<label for="" class="text-main text-opacity-50 block text-sm">Email</label>
								<input type="email" required ref="email" class="border-b w-full border-divider focus:outline-none placeholder-main py-3">
							</div>
							<div class="mb-8 relative">
								<label for="" class="text-main text-opacity-50 block text-sm">Password</label>
								<input :type="showPass ? 'text' : 'password'" ref="password" required minlength="6" class="border-b w-full border-divider focus:outline-none placeholder-main py-3">
								<div class="absolute right-0 bottom-0 m-2">
									<svg @click="showPass = true" v-if="!showPass" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M3.0892 1.91089L1.91081 3.08927L4.22363 5.4021C3.77438 5.76834 3.3738 6.15842 3.01595 6.54956C1.49095 8.24956 0.833336 10.0001 0.833336 10.0001C0.833336 10.0001 3.33334 16.6667 10 16.6667C11 16.6667 11.9079 16.5166 12.7246 16.2582C13.3239 16.0751 13.8739 15.8347 14.3734 15.5518L16.9108 18.0893L18.0892 16.9109L3.0892 1.91089ZM10 3.33341C9 3.33341 8.09206 3.48361 7.27539 3.74194L8.65886 5.12541C9.07552 5.04207 9.525 5.00008 10 5.00008C14.4 5.00008 16.6171 8.55841 17.3421 10.0001C17.0671 10.5501 16.5591 11.4173 15.8008 12.2673L16.9841 13.4506C18.5091 11.7506 19.1667 10.0001 19.1667 10.0001C19.1667 10.0001 16.6667 3.33341 10 3.33341ZM5.4069 6.58537L7.13216 8.31063C6.83288 8.81019 6.66667 9.38419 6.66667 10.0001C6.66667 10.0667 6.66647 10.1417 6.67481 10.2084C6.77481 11.8834 8.11667 13.2253 9.79167 13.3253C9.85833 13.3336 9.93334 13.3334 10 13.3334C10.6159 13.3334 11.1899 13.1672 11.6895 12.8679L13.1315 14.31C12.5903 14.5679 11.9904 14.7665 11.3411 14.8748C10.9161 14.9581 10.475 15.0001 10 15.0001C5.6 15.0001 3.38288 11.4417 2.65788 10.0001C2.93288 9.45008 3.43255 8.58283 4.19922 7.73283C4.54043 7.34169 4.94932 6.95161 5.4069 6.58537ZM10.2083 6.67489L13.3252 9.79175C13.2252 8.11675 11.8833 6.77489 10.2083 6.67489ZM8.39193 9.57039L10.4297 11.6082C10.2971 11.6491 10.1489 11.6667 10 11.6667C9.08333 11.6667 8.33333 10.9167 8.33333 10.0001C8.33333 9.85119 8.35094 9.70299 8.39193 9.57039Z"
											fill="#263238" />
									</svg>
                  <svg  @click="showPass = false" v-else width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M3.0892 1.91089L1.91081 3.08927L4.22363 5.4021C3.77438 5.76834 3.3738 6.15842 3.01595 6.54956C1.49095 8.24956 0.833336 10.0001 0.833336 10.0001C0.833336 10.0001 3.33334 16.6667 10 16.6667C11 16.6667 11.9079 16.5166 12.7246 16.2582C13.3239 16.0751 13.8739 15.8347 14.3734 15.5518L16.9108 18.0893L18.0892 16.9109L3.0892 1.91089ZM10 3.33341C9 3.33341 8.09206 3.48361 7.27539 3.74194L8.65886 5.12541C9.07552 5.04207 9.525 5.00008 10 5.00008C14.4 5.00008 16.6171 8.55841 17.3421 10.0001C17.0671 10.5501 16.5591 11.4173 15.8008 12.2673L16.9841 13.4506C18.5091 11.7506 19.1667 10.0001 19.1667 10.0001C19.1667 10.0001 16.6667 3.33341 10 3.33341ZM5.4069 6.58537L7.13216 8.31063C6.83288 8.81019 6.66667 9.38419 6.66667 10.0001C6.66667 10.0667 6.66647 10.1417 6.67481 10.2084C6.77481 11.8834 8.11667 13.2253 9.79167 13.3253C9.85833 13.3336 9.93334 13.3334 10 13.3334C10.6159 13.3334 11.1899 13.1672 11.6895 12.8679L13.1315 14.31C12.5903 14.5679 11.9904 14.7665 11.3411 14.8748C10.9161 14.9581 10.475 15.0001 10 15.0001C5.6 15.0001 3.38288 11.4417 2.65788 10.0001C2.93288 9.45008 3.43255 8.58283 4.19922 7.73283C4.54043 7.34169 4.94932 6.95161 5.4069 6.58537ZM10.2083 6.67489L13.3252 9.79175C13.2252 8.11675 11.8833 6.77489 10.2083 6.67489ZM8.39193 9.57039L10.4297 11.6082C10.2971 11.6491 10.1489 11.6667 10 11.6667C9.08333 11.6667 8.33333 10.9167 8.33333 10.0001C8.33333 9.85119 8.35094 9.70299 8.39193 9.57039Z"
											fill="#263238" />
									</svg>
								</div>

							</div>
							<div class="mt-20">
								<button type="submit" class="btn-primary">Login</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
  name: 'Login',
  data(){
    return {
      showPass: false
    }
  },
  created(){
    this.loadSetting()
  },
  methods: {
    login(){
      let email = this.$refs.email.value
      let password = this.$refs.password.value
      if(email.length > 0 && password.length > 0){
      this.$axios.post('/login', {email, password})
        .then(res => {
          localStorage.setItem('property-user-name', res.data.data.name);
          localStorage.setItem('property-user-email', res.data.data.email);
          localStorage.setItem('property-user-mobile', res.data.data.mobile);
          localStorage.setItem('property-user-token', res.data.data.token);
          if(res.data.data.push_notification_allowed){
            pushpad("subscribe", isSubscribed => {
              if (isSubscribed) {
                pushpad('uid', res.data.data.user_uuid, res.data.data.signature)
                pushpad('tags', ['mobile_app']);
                window.sessionStorage.setItem('notification-status', 'mobile-subscribed');
              } else {
               console.log("Notifications are blocked from browser preferences.");
              }
            });
          }
            this.$router.replace('/');
          })
          .catch(err => {
            swal({
              title: "Login Failed",
              text: err.response.data.message,
              icon: "error"
            })
          })
      }else{
        swal({
          title: "Please enter Email and Password",
          icon: "info"
        })
      }
    },
    loadSetting(){
      var host = window.location.host
      let data = this.$axios.get('client_manifest', {
        params: {mobile_domain: host}
      })
      .then(res=>{
        localStorage.setItem('crm-logo-url', res.data.logo);
      })
      .catch(err => console.log(err));
    }
  },
  computed: {
    companyLogo(){
      if(localStorage.getItem('crm-logo-url') != "null"){
        console.log(localStorage.getItem('crm-logo-url'));
        return localStorage.getItem('crm-logo-url')
      }
      {
        return '@/assets/logo.png'
      }
    },
    isNULLImage(){
      if(localStorage.getItem('crm-logo-url') != "null"){
        return false
      }
      else{
        return true
      }
    }
  }
}
</script>
<style scoped>
	.login-bg {
    @apply relative;
		background: url('../assets/bg-image-b.jpg') no-repeat center bottom;
		background-size: cover;
	}
  .logo{
    max-width: 200px;
    max-height: 60px;
  }
</style>
